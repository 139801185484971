import React, { useEffect } from 'react';

const useCarousel = (
  ref: React.RefObject<HTMLDivElement>,
  direction: 'left' | 'right',
  speed: number
) => {
  useEffect(() => {
    const carousel = ref.current;
    if (!carousel) return;

    let animationFrameId: number;
    let isScrolling = false;
    let lastTimestamp: number | null = null;

    const scroll = (timestamp: number) => {
      if (!carousel) {
        animationFrameId = requestAnimationFrame(scroll);
        return;
      }

      if (isScrolling) {
        animationFrameId = requestAnimationFrame(scroll);
        return;
      }

      if (!lastTimestamp) {
        lastTimestamp = timestamp;
        animationFrameId = requestAnimationFrame(scroll);
        return;
      }

      const deltaTime = timestamp - lastTimestamp;
      lastTimestamp = timestamp;

      const totalScrollWidth = carousel.scrollWidth / 2;
      const scrollAmount = speed * (deltaTime / 16); // Normalize speed based on frame time

      try {
        isScrolling = true;

        if (direction === 'right') {
          if (carousel.scrollLeft >= totalScrollWidth - 1) {
            carousel.scrollLeft = 0;
          } else {
            carousel.scrollLeft += scrollAmount;
          }
        } else {
          if (carousel.scrollLeft <= 0) {
            carousel.scrollLeft = totalScrollWidth;
          } else {
            carousel.scrollLeft -= scrollAmount;
          }
        }
      } finally {
        isScrolling = false;
      }

      animationFrameId = requestAnimationFrame(scroll);
    };

    animationFrameId = requestAnimationFrame(scroll);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [ref, direction, speed]);
};

export default useCarousel;