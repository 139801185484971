import React, { useState, useRef } from 'react';
import './App.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useCarousel from './useCarousel';

function App() {
	const [currentImage, setCurrentImage] = useState(0);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const images = [
		{ src: '/diap/slider-1.webp', alt: 'Once upon a time, in the depths of the blockchain, there was a peaceful and powerful giant called Celestia. Born from the ambition to tackle one of the greatest challenges in cryptography – ensuring smooth management and optimal data availability – Celestia quickly established itself as a bold star in this universe. Driven by a vision of the future and a unique modular architecture, it immediately attracted a community of dreamers and pioneers, fascinated by the immense potential of this new technology.' },
		{ src: '/diap/slider-2.webp', alt: 'One day, the Celestial Mammoth appeared. Majestic and colossal, it became both guardian and explorer, transporting tons of data from one blockchain world to another. With unexpected grace for its size, it traversed the ages, solidifying each brick of its technology to build an ecosystem where data flowed like light in the cosmos. Every project, every dApp that joined Celestia found in it a foundation of stability and flexibility that propelled the universe toward unexplored horizons.' },
		{ src: '/diap/slider-3.webp', alt: "As it evolved, Celestia continued to innovate, with each new step giving birth to new Mammoths, even more powerful creatures, ready to push the limits of the possible. Capable of handling spectacular data throughputs thanks to advances like Vacuum! and compact blocks, these mammoths surpassed everything that had been imagined before. With each advance, a voice seemed to whisper to the Celestial Mammoths: 'Further... always further.' And so, one day, during its explorations, the Celestial Mammoth crossed a new frontier: Eclipse, a pioneering blockchain, a crossroads between Solana, Ethereum, and Celestia, built to continually push the boundaries of innovation. On Eclipse, the mammoth discovered a world designed to welcome modular solutions like those of Celestia – a world built for the boldest and most inventive creatures..." }
	];

	const carouselRef = useRef<HTMLDivElement>(null);

	useCarousel(carouselRef, 'right', 3);

	const changeImage = (newIndex: number) => {
		setTimeout(() => {
			setCurrentImage(newIndex);
		}, 600); // Match this with CSS transition duration
	};

	// Ajout du préchargement de la première image dans useEffect
	React.useEffect(() => {
		const preloadImage = new Image();
		preloadImage.src = images[0].src;
	}, []);

	return (
		<>
			{/* Ajouter une balise link pour le préchargement dans le head */}
			<link rel="preload" as="image" href="/diap/slider-1.webp" />
			
			{/* Header */}
			<header className='header'>
				{/* Burger Menu */}
				<div 
					className={`burger-menu ${isMenuOpen ? 'active' : ''}`}
					onClick={() => setIsMenuOpen(!isMenuOpen)}
				>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className="social-links">
					<a href="https://x.com/CelestMammoth" className="nav-link" target="_blank" rel="noopener noreferrer">
						<img src="/header/twitter-logo.png" alt="Twitter" width={15}/> Twitter
					</a>
					<a href="https://discord.gg/hNd72TezbN" className="nav-link" target="_blank" rel="noopener noreferrer">
						<img src="/header/discord-logo.png" alt="Discord" width={15}/> Discord
					</a>
				</div>
				<img src={'/header/celestial-mammouthlogo.png'} alt="logo" />
				
				{/* Desktop Navigation */}
				<div className="social-links">
					<a 
						href="#collection" 
						className="nav-link"
						onClick={(e) => {
							e.preventDefault();
							document.getElementById('collection')?.scrollIntoView({ behavior: 'smooth' });
						}}
					>
						Collection
					</a>
					<a 
						href="#mint" 
						className="nav-link"
						onClick={(e) => {
							e.preventDefault();
							document.getElementById('mint')?.scrollIntoView({ behavior: 'smooth' });
						}}
					>
						Mint
					</a>
				</div>

				{/* Mobile Navigation */}
				<div className={`mobile-nav ${isMenuOpen ? 'active' : ''}`}>
					<a href="https://x.com/celestial_37812" className="nav-link" target="_blank" rel="noopener noreferrer">
						<img src="/header/twitter-logo.png" alt="Twitter" width={15}/> Twitter
					</a>
					<a href="https://discord.gg/hNd72TezbN" className="nav-link" target="_blank" rel="noopener noreferrer">
						<img src="/header/discord-logo.png" alt="Discord" width={15}/> Discord
					</a>
					<a 
						href="#collection" 
						className="nav-link"
						onClick={(e) => {
							e.preventDefault();
							document.getElementById('collection')?.scrollIntoView({ behavior: 'smooth' });
							setIsMenuOpen(false);
						}}
					>
						Collection
					</a>
					<a 
						href="#mint" 
						className="nav-link"
						onClick={(e) => {
							e.preventDefault();
							document.getElementById('mint')?.scrollIntoView({ behavior: 'smooth' });
							setIsMenuOpen(false);
						}}
					>
						Mint
					</a>
				</div>
			</header>

			{/* Main Image Carousel */}
			<div style={{ position: 'relative', height: 'calc(100vh - 100px)' }}>
				<TransitionGroup>
					<CSSTransition
						key={currentImage}
						timeout={1000}
						classNames="fade"
					>
						<div className="main-container">
							<img 
								src={images[currentImage].src}
								style={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									objectFit: 'cover'
								}}
								alt={images[currentImage].alt}
								fetchPriority="high" // Priorité élevée pour la première image
								width="1920" // Spécifier les dimensions
								height="1080"
							/>
							<div className='main-content'>
								<div></div>
								<div className='text-area'>
									<img src="/diap/visu-slid2.png" width={100} alt="logo" />
									<div className='text-content'>
										{images[currentImage].alt}
									</div>
									<div className='slider-item'>
										<img 
											src="/diap/arrow-left.png" 
											alt="Previous" 
											style={{ cursor: 'pointer' }}
											onClick={() => changeImage((currentImage - 1 + images.length) % images.length)}
										/>
										{images.map((_, index) => (
											<div 
												key={index}
												style={{
													width: '12px',
													height: '12px',
													borderRadius: '50%',
													border: '2px solid white',
													backgroundColor: index === currentImage ? 'white' : 'transparent',
													margin: '0 5px',
													cursor: 'pointer'
												}}
												onClick={() => changeImage(index)}
											/>
										))}
										<img 
											src="/diap/arrow-right.png" 
											alt="Next" 
											style={{ cursor: 'pointer' }}
											onClick={() => changeImage((currentImage + 1) % images.length)}
										/>
									</div>
								</div>
							</div>
						</div>
					</CSSTransition>
				</TransitionGroup>
			</div>

			{/* Collection Section */}
			<div className="main-container bis" id="collection">
				<h1>Collection</h1>
				<div className="collection-area">
					<div className="rotating-carousel" ref={carouselRef}>
						{/* First set of images */}
						{[...Array(6)].map((_, index) => (
							<img 
								key={`first-rev-${index}`}
								src={`/nft/nft-${index}.png`} 
								alt={`NFT ${index + 1}`} 
								className="collection-image" 
								loading="lazy" // Ajout du lazy loading
								width="300" // Spécifier les dimensions
								height="300"
							/>
						))}
						{/* Second set of images */}
						{[...Array(6)].map((_, index) => (
							<img 
								key={`second-rev-${index}`}
								src={`/nft/nft-${index}.png`} 
								alt={`NFT ${index + 1}`} 
								className="collection-image" 
								loading="lazy" // Ajout du lazy loading
								width="300" // Spécifier les dimensions
								height="300"
							/>
						))}
					</div>
				</div>
			</div>

			{/* Mint Section */}
			<div className="main-container third" id="mint">
				<h1>Mint Soon</h1>
				<p>Ready to join the adventure? The ambition of the Celestial Mammoths is infinite, but these unique beings will be limited to just 3,333. Don't miss out on being part of this extraordinary journey!</p>
				<div className="mint-area">
					<img 
						src="mint.png" 
						alt="NFT" 
						className="collection-image"
						loading="lazy"
						width="300"
						height="300"
					/>
				</div>
			</div>
		</>
	);
}

export default App;
